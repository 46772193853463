import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Participant, RegistrantInfo } from '@app/data/models';

@Component({
    selector: 'ui-registrant-details',
    templateUrl: './registrant-details.component.html',
    styleUrls: ['./registrant-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrantDetailsComponent implements OnChanges {

    dataSource: Participant[] = [];
    displayedColumns = [
        // 'userName',
        'email',
        'device',
        'joinTime',
        'leaveTime',
        'durationInSeconds',
        'audioQuality',
        'videoQuality',
        'leaveReason',
    ];

    get topic() {
        if(this.model && this.model.topic) {
            return this.model.topic;
        }

        const meeting = this.zoomMeetings.find(x => x.id === this.model?.meetingId || x.id === this.model?.webinarId);

        return meeting?.topic || '';
    }

    @Input() model: RegistrantInfo | null = null;
    @Input() zoomMeetings: { id: number; topic: string }[] = [];

    update() {
        if (this.model) {
            this.dataSource = [...this.model.participants];
        }
    }

    ngOnChanges(): void {
        this.update();
    }
}
