import { F, M, SPACE } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    Optional,
    Output,
    SimpleChanges,
} from '@angular/core';

import { FormSection, SessionVideoSettings, TokenResult, VideoSettingsViewModel } from '@app/data/models';
import { VideoOption } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { DestroyService } from '@app/shared/services';
import { APP_ENVIRONMENT, APP_INITIAL_POINTER_EVENT, VIDEOJS_DEFAULT_CONFIGURATION } from '@app/shared/tokens';
import { buildMediaAssetUrl, buildProxyUrlForIos, isIos, isIosOrSafari } from '@app/shared/util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, takeUntil, tap, timer } from 'rxjs';

export interface MediaSectionModel extends FormSection {
    id: string;

    title: string;
    description: string;
    isRequired: boolean;
    mediaAssetId: string | null;
    value: any;
}

const SAVE_VIDEO_OPTIONS_MS = 5000;

@Component({
    selector: 'ui-form-section-media',
    templateUrl: './form-section-media.component.html',
    styleUrls: ['./form-section-media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
})
export class FormSectionMediaComponent implements OnChanges {
    currentSrc = '';

    configuration = null;
    controlBarConfiguration: Record<string, any> | null = null;
    player: any = null;
    isPlaying = false;
    videoFinished = false;

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get isRequired() {
        return this.model?.isRequired;
    }

    get hasWatched() {
        return this.model?.value === true;
    }

    get canSavePlayback() {
        return this.videoSettings?.resumePlayback === true;
    }

    get currentTime(): number {
        if (!this.player || !this.videoSettings) {
            return 0;
        }

        return this.player.currentTime();
    }

    @Input() watermark: string | null = '';
    @Input() token: TokenResult | null = null;
    @Input() model: MediaSectionModel | null = null;
    @Input() videoSettings: VideoSettingsViewModel | null = {
        autoplay: false,
        showProgress: true,
        allowScrubbing: true,
        showControls: true,
        showPlayButton: true,
        showPlaybackSpeed: false,
        resumePlayback: false,
        allowListeners: true,
        elapsedTimeInSeconds: 0,
        enableWaitingRoom: false,
        isLiveStream: false,
        showFullscreenButton: true,
        showWatermark: true,
        startTime: VideoOption.None,
        waitingRoomOffsetInSeconds: 0,
    } as VideoSettingsViewModel;
    @Input() readonly = false;

    @Output() readonly markUserActivity: EventEmitter<void> = new EventEmitter();
    @Output() readonly playbackFinished: EventEmitter<void> = new EventEmitter();
    @Output() readonly toggleTheatreMode: EventEmitter<void> = new EventEmitter();
    @Output() readonly saveVideoSettings: EventEmitter<SessionVideoSettings> = new EventEmitter();

    constructor(
        @Optional()
        @Inject(VIDEOJS_DEFAULT_CONFIGURATION)
        private defaultVideoJsConfig: any,
        @Inject(APP_ENVIRONMENT) private env: Environment,
        @Inject(APP_INITIAL_POINTER_EVENT) private hasPointerEventHappened: () => boolean,
        private deviceService: DeviceDetectorService,
        private destroy$: DestroyService,
        private zone: NgZone,
    ) {
        if (!this.defaultVideoJsConfig) {
            this.defaultVideoJsConfig = {
                controls: true,
                preload: 'auto',
                enableDocumentPictureInPicture: false,
                children: {
                    controlBar: {
                        children: [
                            'playToggle',
                            'progressControl',
                            'volumePanel',
                            'remainingTimeDisplay',
                            'fullscreenToggle',
                        ],
                    },
                    videoWatermark: { text: '' },
                    bigPlayButton: {},
                    loadingSpinner: {},
                    tapToPlay: { hasInteracted: () => (window as any).hasPointerEventHappened === true },
                },
            };
        }
    }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            timer(0, SAVE_VIDEO_OPTIONS_MS)
                .pipe(
                    takeUntil(this.destroy$),
                    filter(() => this.isPlaying),
                    tap(() => this.markUserActivity.emit()),
                    filter(() => this.canSavePlayback),
                    tap(() => {
                        this.saveVideoSettings.emit({
                            mediaAssetId: this.model?.mediaAssetId as string,
                            rowKey: this.model?.id as string,
                            elapsedTimeInSeconds: this.currentTime,
                            countDownElapsedTimeInSeconds: null,
                            isComplete: false,
                        });
                    }),
                )
                .subscribe();
        });
    }

    onPlayerEvent({ player, event, data }: { data?: any; player: any; event: { type: string } }) {
        this.currentSrc = player.src();
        this.player = player;

        switch (event.type) {
            case 'playing':
                this.isPlaying = true;
                break;
            case 'pause':
                this.isPlaying = false;
                break;
            case 'ended':
                this.onPlayerEnded();
                break;
            case 'playlistitem':
                this.buildControlbarConfiguration();
                break;
        }
    }

    onPlayerEnded() {
        if (!this.videoSettings) return;

        this.isPlaying = false;
        const { resumePlayback, startTime } = this.videoSettings;

        if (resumePlayback && startTime === VideoOption.None) {
            this.saveVideoSettings.emit({
                mediaAssetId: this.model?.mediaAssetId as string,
                rowKey: this.model?.id as string,
                elapsedTimeInSeconds: 0,
                countDownElapsedTimeInSeconds: 0,
                isComplete: true,
            });
        }

        this.playbackFinished.emit();
        this.videoFinished = true;
    }

    buildControlbarConfiguration() {
        if (!this.videoSettings) return;

        const { showFullscreenButton, showPlayButton, showProgress, allowScrubbing, isLiveStream, showPlaybackSpeed } =
            this.videoSettings;

        const canSeek = allowScrubbing;

        const controlBarChildren: any = {
            playToggle: {},
            progressControl: {},
            volumePanel: {},
            remainingTimeDisplay: {},
            theatreRoomToggle: { click: () => this.toggleTheatreMode.emit() },
            fullscreenToggle: {},
        };

        if (this.deviceService.isMobile()) {
            delete controlBarChildren.theatreRoomToggle;
        }

        if (showPlayButton !== true) {
            delete controlBarChildren.playToggle;
        }

        if (showFullscreenButton !== true) {
            delete controlBarChildren.fullscreenToggle;
        } else if (isIos(this.deviceService)) {
            delete controlBarChildren.fullscreenToggle;
            controlBarChildren.iosFullscreenToggle = {};
        }

        if (canSeek !== true) {
            controlBarChildren.progressControl = { disabled: true };
        }

        if (showProgress !== true) {
            delete controlBarChildren.progressControl;
            delete controlBarChildren.remainingTimeDisplay;
        }

        if (isLiveStream === true) {
            // do not show the remaining time display for live streams
            delete controlBarChildren.remainingTimeDisplay;
        }

        if (showPlaybackSpeed === true) {
            controlBarChildren.playbackRateMenuButton = {};
        }

        this.controlBarConfiguration = {
            children: controlBarChildren,
        };
    }

    buildVideoConfiguration() {
        if (!this.videoSettings || !this.model) return;

        const config = this.defaultVideoJsConfig;

        const {
            autoplay,
            allowListeners,
            showWatermark,
            showFullscreenButton,
            showPlayButton,
            showPlaybackSpeed,
            isLiveStream,
            resumePlayback,
            elapsedTimeInSeconds,
            enableWaitingRoom,
            waitingRoomOffsetInSeconds,
            mediaAssetDurationInSeconds,
        } = this.videoSettings;

        config.autoplay = !enableWaitingRoom && autoplay === true;

        const userActions: any = {
            click: (e: Event) => {
                if (this.player.paused()) {
                    this.player.play();
                } else {
                    this.player.pause();
                }
            },
            hotkeys: {
                muteKey: (e: KeyboardEvent) => e.which === M,
                fullscreenKey: (e: KeyboardEvent) => e.which === F,
                playPauseKey: (e: KeyboardEvent) => {
                    return e.which === SPACE;
                },
            },
        };

        if (showPlayButton !== true) {
            userActions.click = false;
            userActions.hotkeys.playPauseKey = () => false;
        }

        if (showFullscreenButton !== true) {
            userActions.doubleClick = false;
            userActions.hotkeys.fullscreenKey = () => false;
        }

        if (isLiveStream === true && !allowListeners) {
            userActions.click = false;
            userActions.hotkeys.playPauseKey = false;
        }

        if (showPlaybackSpeed === true) {
            config.playbackRates = [0.5, 0.75, 1, 1.5, 2];
        }

        config.children = {
            bigPlayButton: {},
            loadingSpinner: {},
            tapToPlay: { hasInteracted: () => this.hasPointerEventHappened() },
        };

        if (showWatermark) {
            config.children.videoWatermark = { text: `${this.watermark}` };
        }

        if (enableWaitingRoom) {
            config.children.waitingRoom = {
                offset: waitingRoomOffsetInSeconds,
                hasInteracted: () => this.hasPointerEventHappened(),
            };
        }

        const shouldBuildProxyUrl = isIosOrSafari(this.deviceService);
        const mediaAssetSrc = buildMediaAssetUrl(this.model.mediaAssetId as string, this.env);

        const playlist = [
            {
                sources: [
                    {
                        src: shouldBuildProxyUrl
                            ? buildProxyUrlForIos(mediaAssetSrc, this.env, this.token?.token as string)
                            : mediaAssetSrc,
                        type: 'application/vnd.apple.mpegurl',
                    },
                ],
            },
        ];

        if (resumePlayback && elapsedTimeInSeconds > 0) {
            config.currentTime = Math.max(0, Math.min(elapsedTimeInSeconds));
        }

        config.userActions = userActions;
        config.playlist = playlist;

        this.configuration = config;

        const currentTime = config.currentTime || 0;
        const duration = mediaAssetDurationInSeconds;

        this.videoFinished = currentTime >= duration;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['videoSettings'] || changes['model'] || changes['watermark']) {
            this.buildVideoConfiguration();
        }

        if (changes['videoSettings']) {
            this.buildControlbarConfiguration();
        }
    }
}
