<button mat-raised-button (click)="onExport()"><mat-icon>image</mat-icon>Export</button>
<div>
    <mat-list>
        <mat-list-item (click)="onSelectItem(item)" class="item" *ngFor="let item of filteredSessions" matRipple>
            {{ item.name }}
            <ng-container *ngFor="let registrant of item.registrantsInfo">
                <mat-icon
                    class="webinar-icon"
                    *ngIf="registrant"
                    [ngClass]="{
                        'has-registered': registrant.hasRegistered,
                        'has-joined': registrant.hasJoined
                    }"
                    (mouseover)="showTooltip(registrant, origin)"
                    (mouseout)="isTooltipOpen = false"
                    cdkOverlayOrigin
                    #origin="cdkOverlayOrigin"
                    svgIcon="zoom_logo"
                ></mat-icon>
            </ng-container>
        </mat-list-item>
    </mat-list>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOrigin]="registrantOrigin"
    [cdkConnectedOverlayOpen]="isTooltipOpen"
>
    <ui-registrant-details [model]="model" [zoomMeetings]="zoomMeetings"></ui-registrant-details>
</ng-template>
