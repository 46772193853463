<mat-list fxFlex>
    <ng-container *ngFor="let sessionGroup of sessions">
        <ng-container *ngTemplateOutlet="sessionGroupTpl; context: { $implicit: sessionGroup }"></ng-container>
        <ng-container *ngIf="sessionGroup.isVisible">
            <ng-container *ngFor="let entry of sessionGroup.entries">
                <ng-container
                    *ngTemplateOutlet="sessionGroupEntryTpl; context: { group: sessionGroup, entry: entry, data: summary }"
                ></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-list>

<ng-template #sessionGroupTpl let-entry>
    <mat-list-item class="history-entry informational">
        <div matListItemAvatar [ngSwitch]="entry.errorCode">
            <mat-icon
                [color]="entry.isError ? 'warn' : null"
                [ngClass]="{
                    'post-approval-error': entry.isPostapproval && entry.isError,
                    'post-approval': entry.isPostapproval,
                    'post-submission': entry.isPostsubmission,
                    'post-login': entry.isPostlogin,
                    deactivated: entry.errorCode === ErrorCodes.LinkSessionDeactivated
                }"
            >
                <ng-container *ngSwitchCase="ErrorCodes.LinkSubmissionPending">assignment_returned</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSubmissionApproved">verified</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionMerged">merge_type</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionRegistrationPermitted">assignment_turned_in</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionActivated">how_to_reg</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionDeactivated">block</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSubmissionAutoApproved">verified</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkRegistrationEnded">assignment_late</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkNoRegistration">fact_check</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionLimit">error</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkOneTimeAccess">error</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkExpired">hourglass_disabled</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionReset">replay</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionTimeReset">replay</ng-container>
                <ng-container *ngSwitchCase="ErrorCodes.LinkSessionNameChanged">badge</ng-container>
                <ng-container *ngSwitchDefault>article</ng-container>
            </mat-icon>
        </div>
        <time matListItemLine class="small mute">
            {{ entry.timestamp | formatDate }}
        </time>
        <div class="entry-status" matListItemLine>
            <ng-container *ngIf="entry.count > 0; else label">
                <span
                    class="pointer"
                    matRipple
                    matBadgePosition="after"
                    matBadgeOverlap="false"
                    [matBadge]="entry.count"
                    [ngClass]="{
                        'post-approval-error-badge': entry.isPostapproval && entry.isError,
                        'post-approval-badge': entry.isPostapproval,
                        'post-submission-badge': entry.isPostsubmission,
                        'post-login-badge': entry.isPostlogin,
                        'deactivated-badge': entry.errorCode === ErrorCodes.LinkSessionDeactivated
                    }"
                    (click)="onToggleVisibility(entry)"
                >
                    {{ getEntryStatus(entry) }}
                </span>
            </ng-container>
            <ng-template #label>
                <span>
                    {{ getEntryStatus(entry) }}
                </span>
            </ng-template>
        </div>

        <!-- <div matListItemLine>
            <hr class="mute" />
        </div> -->
    </mat-list-item>
    <mat-divider ></mat-divider>
</ng-template>

<ng-template #sessionGroupEntryTpl let-entry="entry" let-group="group" let-data="data">
    <mat-list-item
        class="history-entry"
        [ngClass]="{
            'post-approval-error': group.isPostapprovalError,
            'post-approval': group.isPostapproval,
            'post-submission': group.isPostsubmission,
            'post-login': group.isPostlogin
        }"
    >
        <img
            *ngIf="entry.countryFlag && entry.errorCode === 0; else errorIcon"
            [src]="entry.countryFlag | safeUrl"
            alt="Country"
            matListItemAvatar
            class="country-flag"
        />
        <ng-template #errorIcon>
            <div *ngIf="entry.errorCode; else noAvatar" matListItemAvatar [ngSwitch]="entry.errorCode">
                <mat-icon color="warn" *ngSwitchCase="ErrorCodes.LinkSessionLimit"> error </mat-icon>
                <mat-icon color="warn" *ngSwitchCase="ErrorCodes.LinkRequiredFieldMissing"> error </mat-icon>
                <mat-icon color="warn" *ngSwitchCase="ErrorCodes.LinkFieldMustBeUnique"> error </mat-icon>
                <mat-icon color="warn" *ngSwitchCase="ErrorCodes.LinkRegistrationEnded"> error </mat-icon>
                <mat-icon color="warn" *ngSwitchCase="ErrorCodes.LinkOneTimeAccess"> error </mat-icon>
                <mat-icon color="warn" *ngSwitchCase="ErrorCodes.LinkExpired"> hourglass_disabled </mat-icon>
                <mat-icon *ngSwitchDefault> article </mat-icon>
            </div>
        </ng-template>
        <ng-template #noAvatar>
            <div matListItemAvatar></div>
        </ng-template>
        <h2 matListItemLine (click)="onSelectIpAddress(entry.ipAddress)" class="pointer">
            {{ entry.ipAddress }}
        </h2>
        <time matListItemLine class="small mute">
            {{ entry.timestamp | formatDate }}
        </time>
        <div class="entry-status" matListItemLine>
            {{ getEntryStatus(entry) }}
        </div>
        <div
            *ngIf="entry.status === SessionStatus.Failed && entry.errorCode === ErrorCodes.LinkFieldMustBeUnique"
            matListItemLine
        >
            Failed on unique field: {{ getEntrySubmissionFailure(entry.meta) }}
        </div>
        <div *ngIf="entry.city" matListItemLine>
            <a
                target="_blank"
                rel="noopener nofollow"
                href="https://www.bing.com/maps?cp={{ entry.latitude }}~{{ entry.longitude }}&lvl=15&v=2&sV=2"
                >{{ entry.city }}, {{ entry.region }}, {{ entry.country }}</a
            >
        </div>
        <div *ngIf="entry.deviceInfo" matListItemLine class="pointer" (click)="onSelectDevice(entry.deviceInfo)">
            {{ entry.deviceInfo.browser }} ({{ entry.deviceInfo.browserVersion }}) {{ entry.deviceInfo.os }}
            {{ entry.deviceInfo.osVersion }}
        </div>
    </mat-list-item>
</ng-template>
