import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';

import {
    ApplicationInfo,
    Credentials,
    CurrentUser,
    TokenResult,
    UpdatePasswordModel,
    UserProfile,
    VisitorRequestModel,
} from '../models';
import { errorHandler, getData, verifyServerResult } from '../operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient, private store: Store) {}

    getServerInfo(): Observable<ApplicationInfo> {
        return this.http
            .get<ServerResult<ApplicationInfo>>(`${this.env.proxyServerUrl}/info`)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    login(credentials: Credentials): Observable<TokenResult> {
        return this.http
            .post<ServerResult<TokenResult>>(`${this.env.proxyServerUrl}/auth/get-token`, credentials)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    logout(): Observable<void> {
        return this.http
            .get<ServerResult<void>>(`${this.env.proxyServerUrl}/logout`)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    getCurrentUser(): Observable<CurrentUser> {
        return this.http
            .post<ServerResult<CurrentUser>>(`${this.env.proxyServerUrl}/users/current`, null)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    saveUserProfile(profile: UserProfile): Observable<UserProfile> {
        return this.http
            .post<ServerResult<UserProfile>>(`${this.env.serverUrl}/profile`, profile)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    changePassword(model: UpdatePasswordModel): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.serverUrl}/auth/update-password`, model)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    updateCookie(): Observable<void> {
        return this.http
            .post<ServerResult<void>>(`${this.env.proxyServerUrl}/auth/token/update-cookie`, null)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    verifyRequest(visitorId: string, requestId: string): Observable<VisitorRequestModel> {
        return this.http.get<VisitorRequestModel>(`${this.env.fpEndpointUrl}/worker/verify/request`, {
            params: { visitorId, requestId },
        });
    }

    getCurrentToken(): Observable<TokenResult> {
        return this.http
            .post<ServerResult<TokenResult>>(`${this.env.proxyServerUrl}/auth/token/me`, null)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    cookieTest(id?: string): Observable<any> {
        return this.http
            .post<ServerResult<any>>(`${this.env.proxyServerUrl}/ct`, { id })
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    loadDeviceId(visitorId: string): Observable<any> {
        return this.http
            .post<ServerResult<any>>(`${this.env.proxyServerUrl}/device`, { visitorId })
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    heartBeat(): Observable<void> {
        return this.http
            .get<ServerResult<void>>(`${this.env.rapidApiUrl}/heart-beat`)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    activateSession(trackLogin: boolean): Observable<TokenResult> {
        return this.http
            .post<ServerResult<TokenResult>>(
                `${this.env.proxyServerUrl}/link/current/activate`,
                trackLogin ? 'true' : 'false',
            )
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    refreshToken(): Observable<TokenResult> {
        return this.http
            .post<ServerResult<TokenResult>>(`${this.env.proxyServerUrl}/auth/token/refresh-token`, null)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    sendDiagnosticData(data: any): Observable<string> {
        return this.http
            .post<ServerResult<string>>(`${this.env.proxyServerUrl}/diagnostic`, JSON.stringify(data))
            .pipe(verifyServerResult(), errorHandler(), getData());
    }
}
