import { Point } from '@angular/cdk/drag-drop';
import {
    CdkConnectedOverlay,
    ConnectedPosition,
    FlexibleConnectedPositionStrategyOrigin,
    ScrollStrategy,
    ViewportRuler,
} from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';

import { Subject, take, takeUntil } from 'rxjs';

import { ClipboardModel, FormPage, FormSection, LinkModel, LinkSessionSummary, Submission } from '@app/data/models';
import { FormQuestionType, FormSectionType } from '@app/shared/enums';

@Component({
    selector: 'ui-form-submissions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-submissions.component.html',
    styleUrls: ['./form-submissions.component.scss'],
})
export class FormSubmissionsComponent implements OnChanges, OnInit, OnDestroy {
    private isAlive$ = new Subject<void>();
    sections: FormSection[] = [];
    FormSectionType: typeof FormSectionType = FormSectionType;
    FormQuestionType: typeof FormQuestionType = FormQuestionType;

    private _panelOpen = false;
    tooltipSessions: LinkSessionSummary[] = [];
    tooltipLabel = '';
    lastPosition: MouseEvent | null = null;
    private _scrollStrategyFactory: () => ScrollStrategy;
    _scrollStrategy: ScrollStrategy;
    _overlayWidth = 400;
    _positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            panelClass: 'mat-mdc-select-panel-above',
        },
    ];

    get panelOpen(): boolean {
        return this._panelOpen;
    }

    get _overlayOrigin(): FlexibleConnectedPositionStrategyOrigin {
        const point: Point = {
            x: this.lastPosition?.clientX ?? 0,
            y: this.lastPosition?.clientY ?? 0,
        };

        return point;
    }

    @ViewChild(CdkConnectedOverlay)
    protected _overlayDir: CdkConnectedOverlay;

    @Input() link: LinkModel | null;
    @Input() includeDraftResponses = false;
    @Input() pages: FormPage[] | null;
    @Input() submissions: Submission[] | null;
    @Input() sessions: LinkSessionSummary[] | null;
    @Input() webinars: { id: number; topic: string }[] | null = [];
    @Input() meetings: { id: number; topic: string }[] | null = [];

    @Output() readonly selectItem = new EventEmitter<LinkSessionSummary>();
    @Output() readonly copyToClipboard = new EventEmitter<ClipboardModel>();

    constructor(
        private cd: ChangeDetectorRef,
        protected viewportRuler: ViewportRuler,
        @Inject(MAT_SELECT_SCROLL_STRATEGY) scrollStrategyFactory: any,
    ) {
        this._scrollStrategyFactory = scrollStrategyFactory;
        this._scrollStrategy = this._scrollStrategyFactory();
    }

    update() {
        if (!this.pages || !this.submissions) {
            return;
        }

        this.sections = [];
        this.sections = this.pages.reduce((acc, page) => {
            page.children.forEach(child => {
                acc.push({
                    id: child.id,
                    title: child.title,
                    description: child.description,
                    type: child.type,
                    hint: child.hint,
                    isRequired: child.isRequired,
                    showPlaybackSpeed: child.showPlaybackSpeed,
                    allowScrubbing: child.allowScrubbing,
                    resumePlayback: child.resumePlayback,
                    mediaAssetId: child.mediaAssetId,
                    futureDatesOnly: child.futureDatesOnly,
                    maxLabel: child.maxLabel,
                    minLabel: child.minLabel,
                    fileId: child.fileId,
                    fileName: child.fileName,
                    options: child.options,
                    matrix: child.matrix,
                    showOther: child.showOther,
                    isEnabled: child.isEnabled,
                    sectionType: child.sectionType,
                    bookingUrl: child.bookingUrl,
                    value: null,

                    paymentProvider: child.paymentProvider,
                    amount: child.amount,
                    hideSections: child.hideSections,
                    productCode: child.productCode,
                    productName: child.productName,

                    webinarId: child.webinarId,
                    webinarUniqueId: child.webinarUniqueId,
                    webinarTopic: child.webinarTopic,
                    webinarAgenda: child.webinarAgenda,
                    webinarTemplateId: child.webinarTemplateId,

                    meetingId: child.meetingId,
                    meetingUniqueId: child.meetingUniqueId,
                    meetingTopic: child.meetingTopic,
                    meetingAgenda: child.meetingAgenda,
                    meetingTemplateId: child.meetingTemplateId,

                    allowGuestRegistration: child.allowGuestRegistration,
                    showJoinUrl: child.showJoinUrl,
                    showJoinButton: child.showJoinButton,
                    showCopyLinkButton: child.showCopyLinkButton,
                });
            });

            return acc;
        }, [] as FormSection[]);
    }

    onSelectItem(item: LinkSessionSummary): void {
        this._panelOpen = false;
        this.selectItem.emit(item);
    }

    onCopyToClipboard(model: ClipboardModel) {
        this.copyToClipboard.emit(model);
    }

    getTotalSubmissionsForSection(section: FormSection) {
        const submittedResults = this.submissions?.filter(s => this.includeDraftResponses || s.isSubmitted) as Submission[];
        return `${
            submittedResults.filter(s => {
                if (this.includeDraftResponses) {
                    return s.results.some(r => r.id === section.id);
                }

                return s.submittedResults.some(r => r.id === section.id);
            }).length
        } / ${submittedResults.length}`;
    }

    ngOnInit() {
        this.viewportRuler
            .change()
            .pipe(takeUntil(this.isAlive$))
            .subscribe(() => {
                if (this.panelOpen) {
                    this.cd.detectChanges();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['submission'] || changes['pages']) {
            this.update();
        }
    }

    close(): void {
        if (this._panelOpen) {
            this._panelOpen = false;
            this.cd.markForCheck();
        }
    }

    _onAttached(): void {
        this._overlayDir.positionChange.pipe(take(1)).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    onShowDetails({
        sessions,
        event,
        section,
        dataIndex,
    }: {
        event: MouseEvent;
        sessions: LinkSessionSummary[];
        section: FormSection;
        dataIndex: number;
    }) {
        this._panelOpen = true;
        this.lastPosition = event;
        this.tooltipSessions = sessions;
        this.tooltipLabel = section.options[dataIndex]['text'] as string;

        this.cd.markForCheck();
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}
