<ng-container *ngIf="isActivated" [ngSwitch]="displayAs">
    <time class="active" *ngSwitchCase="DisplayAs.RegistrationReset">
        {{ resetDisplay }} ({{ resetStartDate | formatDate }})
        <span *ngIf="!isExpired && expires">{{ expires | formatDate }}</span>
    </time>
    <time class="active" *ngSwitchCase="DisplayAs.Timer">
        <mat-icon>timer</mat-icon>{{ linkInactivityMode === LinkInactivityMode.Link ? 'Link Timer' : 'User Timer' }} ({{
            timer
        }}{{ timerUnit | timerUnit }})
        <span *ngIf="!isExpired && expires">{{ expires | formatDate }}</span>
    </time>
    <time class="expired" *ngSwitchCase="DisplayAs.Expired"
        ><mat-icon color="warn">timer</mat-icon> Link expired for User
    </time>
    <time class="active" *ngSwitchCase="DisplayAs.EndDate"> End Date {{ endDate | formatDate }} </time>
    <button mat-raised-button *ngIf="!isInfluencerAuthenticated && allowUserToRequestMoreTime" (click)="onRequestMoreTime()">
        <mat-icon matListItemIcon>alarm_add</mat-icon>
        <h4 matListItemTitle>Request More Time</h4>
    </button>
</ng-container>
<ng-container *ngIf="!isActivated && isInfluencerAuthenticated">
    <div><mat-icon class="pending">timer</mat-icon> Link not activated by User</div>
</ng-container>
