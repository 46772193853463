import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { LinkInfo } from '@app/data/models';
import { LinkExpiration, LinkInactivityMode, RegistrationMode, TimerUnit } from '@app/shared/enums';
import { getFormSubmissionResetDate, isDate } from '@app/shared/util';

enum DisplayAs {
    None,
    EndDate,
    Timer,
    RegistrationReset,
    Expired,
}

@Component({
    selector: 'ui-link-expiration',
    templateUrl: './link-expiration.component.html',
    styleUrls: ['./link-expiration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkExpirationComponent implements OnChanges {
    DisplayAs: typeof DisplayAs = DisplayAs;
    LinkInactivityMode: typeof LinkInactivityMode = LinkInactivityMode;

    isExpired = false;
    expires: Date | null = null;
    isActivated = false;
    timer = 0;
    endDate: Date | null = null;
    timerUnit: TimerUnit = TimerUnit.Minutes;
    registrationEndDate: Date | null = null;
    linkInactivityMode: LinkInactivityMode = LinkInactivityMode.Default;
    displayAs = DisplayAs.None;
    resetDisplay: string | null = null;
    resetStartDate: Date | null = null;

    @Input() linkInfo: LinkInfo | null = null;
    @Input() sessionExpiration: Date | null = null;
    @Input() isInfluencerAuthenticated: boolean | null = null;
    @Input() allowUserToRequestMoreTime: boolean | null = null;

    @Output() readonly requestMoreTime: EventEmitter<void> = new EventEmitter();

    onRequestMoreTime(): void {
        this.requestMoreTime.emit();
    }

    update() {
        if (!this.linkInfo) {
            return;
        }

        const {
            linkExpiration,
            activationDate,
            timer,
            linkEndDate,
            timerEndDate,
            registrationMode,
            linkInactivityMode,
            timerUnit,
        } = this.linkInfo;
        const isActivated = activationDate !== null;
        const now = new Date();
        let displayAs = DisplayAs.EndDate;
        let expires: Date | null = null;
        let resetStartDate: Date | null = null;
        let resetDisplay = null;

        if (registrationMode !== RegistrationMode.Default) {
            displayAs = DisplayAs.RegistrationReset;
            resetStartDate = getFormSubmissionResetDate(this.linkInfo);
            switch (registrationMode) {
                case RegistrationMode.Daily:
                    resetDisplay = 'Expires Daily';
                    break;
                case RegistrationMode.Weekly:
                    resetDisplay = `Expires Weekly`;
                    break;
                case RegistrationMode.Monthly:
                    resetDisplay = `Expires Monthly`;
                    break;
            }
        } else if (linkExpiration === LinkExpiration.Date) {
            if (isActivated || linkEndDate) {
                displayAs = DisplayAs.EndDate;
            } else {
                displayAs = DisplayAs.None;
            }
        } else if (linkExpiration === LinkExpiration.Timer) {
            displayAs = DisplayAs.Timer;
        } else {
            displayAs = DisplayAs.None;
        }

        if (linkExpiration === LinkExpiration.Date) {
            expires = new Date(linkEndDate);
        } else if (linkExpiration === LinkExpiration.Timer && isDate(timerEndDate)) {
            expires = new Date(timerEndDate);
        } else if (
            linkExpiration === LinkExpiration.Timer &&
            linkInactivityMode === LinkInactivityMode.Link &&
            activationDate !== null
        ) {
            let expirationDate = new Date();

            const year = activationDate.getFullYear();
            const month = activationDate.getMonth();
            const day = activationDate.getDate();
            const hour = activationDate.getHours();
            const min = activationDate.getMinutes();
            const timer = this.linkInfo.timer as number;

            switch (timerUnit) {
                case TimerUnit.Months:
                    expirationDate = new Date(year, month, day + timer * 30, hour, min);
                    break;
                case TimerUnit.Weeks:
                    expirationDate = new Date(year, month, day + timer * 7, hour, min);
                    break;
                case TimerUnit.Days:
                    expirationDate = new Date(year, month, day + timer, hour, min);
                    break;
                case TimerUnit.Hours:
                    expirationDate = new Date(year, month, day, hour + timer, min);
                    break;
                case TimerUnit.Minutes:
                    expirationDate = new Date(year, month, day, hour, min + timer);
                    break;
            }

            expires = expirationDate;
        } else if (
            linkExpiration === LinkExpiration.Timer &&
            linkInactivityMode === LinkInactivityMode.User &&
            !this.isInfluencerAuthenticated &&
            this.sessionExpiration
        ) {
            expires = this.sessionExpiration;
        }

        if (expires && now > expires) {
            this.isExpired = true;
            displayAs = DisplayAs.Expired;
        }

        this.expires = expires;
        this.displayAs = displayAs;
        this.isActivated = isActivated;
        this.resetStartDate = resetStartDate;
        this.resetDisplay = resetDisplay;
        this.timer = timer;
        this.timerUnit = timerUnit;
        this.linkInactivityMode = linkInactivityMode;
        this.endDate = linkEndDate ? new Date(linkEndDate) : null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.update();
    }
}
