<ng-container *ngIf="currentPage">
    <ui-form-section class="section" [model]="currentPage"></ui-form-section>
    <ng-container *ngFor="let child of currentPage.children; let index = index">
        <ng-container [ngSwitch]="child.sectionType">
            <ui-form-section-text
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.Text"
                [model]="child"
            ></ui-form-section-text>
            <ui-form-section-media
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.Media"
                [token]="token"
                [model]="child"
                [videoSettings]="getVideoSettings(child)"
            ></ui-form-section-media>
            <ui-form-section-question
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.Question"
                [model]="child"
                (valueChanged)="onValuedChanged(child, $event)"
            ></ui-form-section-question>
            <ui-form-section-download
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.File"
                [model]="child"
            ></ui-form-section-download>
            <ui-form-section-event
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.Event"
                [model]="child"
            ></ui-form-section-event>
            <ui-form-section-webinar
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.WebinarRegistration"
                [registrant]="registrant"
                [model]="child"
                (registerForWebinar)="onRegisterForWebinar()"
            ></ui-form-section-webinar>
            <ui-form-section-meeting
                [uiPreview]="showPreview"
                class="section"
                *ngSwitchCase="FormSectionType.MeetingRegistration"
                [registrant]="registrant"
                [model]="child"
                (registerForMeeting)="onRegisterForWebinar()"
            ></ui-form-section-meeting>
        </ng-container>
    </ng-container>
</ng-container>

<section class="actions">
    <button mat-raised-button *ngIf="isBackVisible" [disabled]="!isBackEnabled" (click)="onBack()">Back</button>
    <button mat-raised-button *ngIf="isNextVisible" [disabled]="!isNextEnabled" color="primary" (click)="onNext()">
        Next
    </button>
    <button mat-raised-button *ngIf="isSubmitVisible" [disabled]="!isSubmitEnabled" color="primary" (click)="onSubmit()">
        Submit
    </button>
</section>
