import { Inject, Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

import { BlobContainer } from '@app/shared/enums';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { TransferProgressEvent } from '@azure/core-http';
import {
    BlobDownloadResponseParsed,
    BlobGetPropertiesResponse,
    BlobItem,
    BlobServiceClient,
    BlobUploadCommonResponse,
} from '@azure/storage-blob';
import { getContainerName } from '@app/shared/util';

@Injectable({
    providedIn: 'root',
})
export class BlobService {
    client: BlobServiceClient;

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment) {}

    ensureClient(): BlobServiceClient {
        if (!this.client) {
            this.client = new BlobServiceClient(this.env.blobStorageUrl);
        }

        return this.client;
    }

    uploadBlobBlock(
        name: string,
        content: string,
        blobContentType: string,
        containerName: string | BlobContainer = BlobContainer.Private,
    ): Observable<any> {
        const client = this.ensureClient();
        const container = client.getContainerClient(this.getContainerName(containerName));

        const blob = container.getBlockBlobClient(name);

        return from(
            blob.upload(content, content.length, {
                blobHTTPHeaders: {
                    blobContentType,
                    // blobCacheControl: 'no-store, no-cache, must-revalidate, max-age=0', // Allow for fastly to cache instead of azure blob storage
                },
            }),
        );
    }

    getBlobMetadata(
        name: string,
        containerName: string | BlobContainer = BlobContainer.Private,
    ): Observable<BlobGetPropertiesResponse> {
        const client = this.ensureClient();
        const container = client.getContainerClient(this.getContainerName(containerName));

        const blob = container.getBlockBlobClient(name);

        return from(blob.getProperties());
    }

    async getBlobUri(name: string, containerName: string | BlobContainer = BlobContainer.Private): Promise<string> {
        const client = this.ensureClient();
        const container = client.getContainerClient(this.getContainerName(containerName));

        const blob = container.getBlockBlobClient(name);

        return blob.url;
    }

    async listBlobs(prefix: string, containerName: string | BlobContainer = BlobContainer.Private): Promise<BlobItem[]> {
        const client = this.ensureClient();
        const container = client.getContainerClient(this.getContainerName(containerName));
        const results = [];

        for await (const response of container.listBlobsFlat({ prefix }).byPage({ maxPageSize: 100 })) {
            results.push(...response.segment.blobItems);
        }

        return results;
    }

    downloadBlobBlock(
        name: string,
        containerName: string | BlobContainer = BlobContainer.Private,
    ): Observable<BlobDownloadResponseParsed> {
        const client = this.ensureClient();
        const container = client.getContainerClient(this.getContainerName(containerName));

        const blob = container.getBlockBlobClient(name);

        return from(blob.download());
    }

    uploadFile(
        name: string,
        file: Buffer | Blob | ArrayBuffer | ArrayBufferView,
        fileType: string,
        containerName: string | BlobContainer = BlobContainer.Private,
        abortSignal?: AbortSignal,
    ): Observable<TransferProgressEvent | BlobUploadCommonResponse> {
        const client = this.ensureClient();
        const container = client.getContainerClient(this.getContainerName(containerName));

        const blob = container.getBlockBlobClient(name);

        const observable = new Observable<TransferProgressEvent | BlobUploadCommonResponse>(sub => {
            console.log('Uploading file', name, fileType, file);
            try {
                blob.uploadData(file, {
                    blobHTTPHeaders: { blobContentType: fileType },
                    blockSize: 4 * 1024 * 1024,
                    concurrency: 20,
                    onProgress: ev => {
                        ev.loadedBytes;
                        sub.next(ev);
                    },
                    // https://learn.microsoft.com/en-us/javascript/api/overview/azure/abort-controller-readme?view=azure-node-latest
                    abortSignal: abortSignal,
                })
                    .then(res => {
                        sub.next(res);
                        sub.complete();
                    })
                    .catch(err => {
                        console.log('Upload file error', err);
                        sub.error(err);
                        sub.complete();
                    });
            } catch (error) {
                console.log('Upload file error', error);
                sub.error(error);
                sub.complete();
            }
        });

        return observable;
    }

    getContainerName(containerName: string | BlobContainer = BlobContainer.Private): string {
        return getContainerName(this.env, containerName);
    }
}
