import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EmbeddedViewRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    Output,
    ViewContainerRef,
} from '@angular/core';

import { finalize, from, tap } from 'rxjs';

import {
    ClipboardModel,
    EventLinkModel,
    EventLinkSessionSummary,
    FormPage,
    FormSection,
    LinkModel,
    RegistrantInfo,
} from '@app/data/models';
import * as html2canvas from 'html2canvas';

import { FormSubmissionsExportComponent } from '../form-submissions-export/form-submissions-export.component';
import { FormSectionType } from '@app/shared/enums';

@Component({
    selector: 'ui-form-chart-tooltip',
    templateUrl: './form-chart-tooltip.component.html',
    styleUrls: ['./form-chart-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormChartTooltipComponent implements OnChanges {
    zoomMeetings: { id: number; topic: string }[] = [];
    filteredSessions: EventLinkSessionSummary[] = [];
    isTooltipOpen = false;
    model: RegistrantInfo | null = null;
    _origin: CdkOverlayOrigin | null = null;
    private componentRef: ComponentRef<FormSubmissionsExportComponent>;

    get registrantOrigin(): CdkOverlayOrigin {
        return this._origin as CdkOverlayOrigin;
    }

    @Input() category: string | null;
    @Input() link: LinkModel | null;
    @Input() pages: FormPage[] | null;
    @Input() sessions: EventLinkSessionSummary[] | null;
    @Input() webinars: { id: number; topic: string }[] | null = [];
    @Input() meetings: { id: number; topic: string }[] | null = [];

    @Output() readonly selectItem = new EventEmitter<EventLinkSessionSummary>();
    @Output() readonly copyToClipboard = new EventEmitter<ClipboardModel>();

    constructor(private viewContainerRef: ViewContainerRef, private injector: Injector) {}

    onSelectItem(item: EventLinkSessionSummary): void {
        this.selectItem.emit(item);
    }

    onExport() {
        this.createAndAttachComponent();
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        from(html2canvas.default(domElem))
            .pipe(
                tap(canvas => {
                    const contentDataURL = canvas.toDataURL('image/png');
                    const name = [this.link?.url, this.category, 'export.png']
                        .join('-')
                        .replace(/[/:*?"<>|]/g, '')
                        .replace(/ /g, '_');

                    const a = document.createElement('a');
                    a.download = name;
                    a.href = contentDataURL;
                    a.click();

                    // canvas.toBlob(blob => {
                    //     const items = [new ClipboardItem({ 'image/png': blob as Blob })];
                    //     // navigator.clipboard.write(items);
                    //     this.copyToClipboard.emit({ message: 'Image copied to clipboard', items });
                    // }, 'image/png');
                }),
                finalize(() => this.removeComponent()),
            )
            .subscribe();
    }

    showTooltip(model: RegistrantInfo, origin: CdkOverlayOrigin) {
        this._origin = origin;
        this.isTooltipOpen = true;
        this.model = model;
    }
    // Method to create and attach the component
    createAndAttachComponent() {
        // Create a component reference
        this.componentRef = this.viewContainerRef.createComponent(FormSubmissionsExportComponent, {
            injector: this.injector,
        });
        this.componentRef.instance.category = this.category;
        this.componentRef.instance.link = this.link;
        this.componentRef.instance.sessions = this.filteredSessions;
        this.componentRef.instance.ngOnChanges();
        this.componentRef.changeDetectorRef.detectChanges();

        // Get DOM element from the component
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // Append the component to the body
        document.body.appendChild(domElem);
    }

    // Method to remove the component
    removeComponent() {
        this.componentRef.destroy();
    }

    ngOnChanges(): void {
        this.filteredSessions = (this.sessions ?? []).sort((a, b) => a.name.localeCompare(b.name));

        if (this.pages) {
            this.zoomMeetings = this.pages
                .reduce(
                    (acc: FormSection[], page: FormPage) => acc.concat(page.children as FormSection[]),
                    [] as FormSection[],
                )
                .filter(
                    (s: FormSection) =>
                        s.sectionType === FormSectionType.WebinarRegistration ||
                        s.sectionType === FormSectionType.MeetingRegistration,
                )
                .map((s: FormSection) => {
                    const meeting =
                        this.webinars?.find(w => w.id === s.webinarId) || this.meetings?.find(m => m.id === s.meetingId);

                    if (meeting) {
                        return meeting;
                    }

                    return { id: 0, topic: 'Meeting not found' };
                });
        } else {
            this.zoomMeetings = [];
        }
    }
}
